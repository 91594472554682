import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/pgegenfurtner/Projekte/interness/atacama/atacama-packages/packages/web-core/src/templates/mdx/default.js";
import { FaCalendarAlt } from 'react-icons/fa';
import Heading from '@interness/web-core/src/components/text/Heading/Heading';
import CalcomHostedEmbed from '@interness/wedding-rings-studio-addon/src/components/CalcomEmbed/CalcomHostedEmbed';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Wrapper mdxType="Wrapper">
      <hr></hr>
  <Heading icon={<FaCalendarAlt mdxType="FaCalendarAlt" />} subtitle="Zur Trauringberatung" mdxType="Heading">Terminvereinbarung</Heading>
  <CalcomHostedEmbed calLink="juwelierreich/trauringberatung" mdxType="CalcomHostedEmbed" />
    </Wrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      